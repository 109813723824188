<template>
    <div>
        <b-row>
      <b-col md="6">
       
            <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      block
      :variant="selectedButton === 'departman' ? 'primary' : 'outline-primary'"
      @click="selectedButton = 'departman'"
    >
      Departman Verimliliği
    </b-button>
</b-col>
<b-col md="6">
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      disabled
      block
      :variant="selectedButton === 'proje' ? 'primary' : 'outline-primary'"
      @click="selectedButton = 'proje'"
    >
      Proje Verimliliği
    </b-button>
      
      </b-col>
    </b-row>
    <b-row class="justify-content-center mt-4"> 
       
        <b-col
          cols="12"
          md="4"
          class="text-center"
          v-if="departmentList.length>0"
         
        >
        <h4>Lütfen Departman Seçiniz</h4> 
          <b-form-group
            label="Departman listesi"
            label-for="user-role"
            v-if="!isLoading"
          >
            <v-select
          
              v-model="selectedDepartment"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="departmentList"
              
              :clearable="false"
                label="label"
              :reduce="value"
              input-id="user-role"
            />
          </b-form-group>
          <router-link v-if="selectedDepartment != ''" :to="{ name: 'productivity-edit', params: { id: selectedDepartment.value }}">
  <button type="button" class="btn btn-primary">Devam Et</button>
</router-link>
<button v-else type="button" class="btn btn-primary disabled">Devam Et</button>
        </b-col>
    </b-row>
    </div>
    
  </template>
  
  <script>
  import { BButton, BCol, BRow ,BFormGroup,BForm} from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { Api } from '@/helpers/Api/apiConfig'
  
  export default {
    components: {
      BButton,
      BCol,
      BRow,  vSelect,BFormGroup,BForm
    },
    data(){
        return{
            departmentList:[{label:'a',value:12},{label:'b',value:1}],
            selectedDepartment:'',
            selectedButton: 'departman'
    }
},
mounted(){
this.getDepartments()
},
methods:{
    async getDepartments() {
    this.isLoading = true;
    await Api("GET", "Department/GetAllActiveDepartments").then((res) => {
      this.departments = res.data.data.activeDepartments;
      this.departmentList = this.departments.map((item) => {
        return {
          label: item.name,
          
          value: item.departmentId,
         
          //isActive:false
        };
      });
      // this.departmentList.unshift({
      //   text: 'Tüm Departmanlar',
      //   value: null,
     
      // }) 
      // this.$emit('departmentList',this.departmentList)

      //this.totalRows = this.departmentList.length
      console.log( 'userrdepartments',this.departments);
      this.isLoading = false;
    });
  },
},
    directives: {
      Ripple,
    },
  }
  </script>
   <style lang="scss">
   @import '@core/scss/vue/libs/vue-select.scss';
   </style>